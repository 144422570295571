import "primereact/resources/themes/saga-orange/theme.css"
import "primereact/resources/primereact.min.css"
import "primeicons/primeicons.css"
import 'primeflex/primeflex.css';
import * as React from "react"
import {Layout} from "../containers/Layout";
import {PageProps} from "gatsby";
import styled from 'styled-components';
import {Palette} from "../constants";
import {CTA} from "../components/CTA";


export default function Blocked(props: PageProps) {
    const params = new URLSearchParams(props.location.search);
    const appId = params.get('appid');
    return <StyledLayout {...props} meta={{title: 'Blocked'}} appBarProps={{onBkg: 'yellow'}}>
        <Center id={'blocked'}>
            <h1>Blocked</h1>
            {appId && <CTA to={`https://play.google.com/store/apps/details?id=${appId}`} target={"_blank"}>Open unprn app</CTA>}
        </Center>
    </StyledLayout>
}

const StyledLayout = styled(Layout)`
  background-color: #${Palette.MaximumYellowRed.hex};
  align-items: center;
  
  .main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;    
  }
`;

const Center = styled.div`
  min-height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    font-size: 4rem;
  }
`;
